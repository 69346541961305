import request from '@/utils/request'

// 开门
export function open(params) {
  return request({
    url: '/store/store-shop/storeOpen',
    method: 'get',
    params
  })
}
// 所属客服负责的门店list
export function fetchList(params) {
  return request({
    url: '/store/store-shop/page',
    method: 'get',
    params
  })
}
// 获取摄像头列表
export function cameraFetchList(params) {
  return request({
    url: '/store/store-monitor/page',
    method: 'get',
    params
  })
}

// 修改设备锁门状态
export function putSuomenStatus(params) {
  return request({
    url: '/store/store-shop/storeClose',
    method: 'get',
    params
  })
}