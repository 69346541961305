<template>
  <div :id="`video-container-${index}`"></div>
</template>

<script>
// import EZUIKit from "ezuikit-js";
import EZUIKit from '@/amisComponents/cham_ys_camera/ezuikit'
import { getYsToken } from './api'
export default {
  props: {
    deviceCode: {
      type: [String, Number],
      default: null,
    },
    sfSound: {
      type: Number,
      default: 1,
    },
    index: {
      type: [Number, String],
      defalut: 0,
    },
    chanell: {
      type: [Number, String],
      default: 1
    },
    isBack: { // 是否回放
      type: Boolean,
      default: false
    },
    height: {
      type: [String,Number],
      default: 500
    },
    time: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      player: null,
    };
  },
  watch: {
    chanell(value) {
      this.handlePlay()
    },
    time: {
      handler(value) {
        console.log(this.time, this.deviceCode, '46----------');
        this.handlePlay()
      },
      deep: true
    }
  },
  created() {
    this.handlePlay()
  },
  beforeDestroy() {
    if (this.player) {
      console.log(this.player, '57------------');
      this.player.stop();
      this.player.destroy()
      console.log(this.$el);
    }
  },
  methods: {
    click() {
      this.$emit('click')
    },
    handlePlay() {
      getYsToken().then((res) => {
        console.log(res, '65>>>>>>>>');
        let url = this.isBack?`${this.chanell}.local.rec?begin=${this.time[0]}&end=${this.time[1]}`:`${this.chanell}.live`
        if(!this.player) {
          this.player = new EZUIKit.EZUIKitPlayer({
            id: `video-container-${this.index}`, // 视频容器ID
            accessToken: res.data,
            url: `ezopen://open.ys7.com/${this.deviceCode}/${url}`,
            template: this.sfSound === 0 ? "pcLive" : "ad4b051b890c44f0a1d9be35d2895307", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
            plugin: [this.sfSound === 0 ? "talk" : ""], // 加载插件，talk-对讲
            audio: this.sfSound === 0 ? 1 : 0,
            // width: 600,
            height: this.height,
            handleError: function(err) {
              console.log(err);
            }
          });
        } else {
          console.log(url, '75-----------');
          this.player.stop()
          this.player.play({
            url:`ezopen://open.ys7.com/${this.deviceCode}/${url}`,
            accessToken: res.data.data,
            handleError: function(err) {
              console.log(err);
            }
          })
        }
        console.log(this.player, '99>>>>>>>');
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
