<template>
  <div>
    <div class="flex align-center flex-wrap">
      <!-- {{mqttBackInfo.name}}-摄像头预览 -->
      <!-- <a-button type="primary" style="margin-left: auto;margin-right: 10px;" @click="handleOpen">开门</a-button> -->
      <a-button style="margin-left: auto" type="primary" @click="handleBack">返回</a-button>
    </div>
    <!-- <ys-video
      style="width: 50%"
      v-for="(item, index) in shexiangtouList"
      :key="item.id"
      :deviceCode="item.deviceCode"
      :index="index"
      :sfSound="item.sfSound"
    ></ys-video> -->
    <a-layout class="layout">
      <a-layout>
        <a-layout-content class="layout-main">
          <template v-if="zhuCamarea && isShowCamera">
            <div style="width: 100%;height: 100%">
              <ys-video
                style="width: 100%"
                :height="(500-32)"
                :deviceCode="zhuCamarea.deviceCode"
                :index="'zhu'"
                :sfSound="zhuCamarea.sfSound"
              ></ys-video>
            </div>
            
          </template>
          
        </a-layout-content>
        <a-layout-footer class="layout-footer flex">
          <template v-if="cameraListUnFirstList.length && isShowCamera">
            <div class="layout-footer-item" v-for="(item,index) in cameraListUnFirstList" :key="item.id">
              <a @click="handleSelectCamera(item)">在上方显示</a>
              <ys-video
                style="width: 100%"
                :height="(150-50)"
                :deviceCode="item.deviceCode"
                :index="index"
                :sfSound="item.sfSound"
                @click="handleSelectCamera(item, index)"
              ></ys-video>
            </div>
          </template>
          
        </a-layout-footer>
      </a-layout>
      <a-layout-sider :width="300" class="layout-right">
        <a-card class="c-card" :class="cameraQueryParam.shopId == item.id?'active':''" :bordered="true" v-for="(item,index) in storeList" :key="item.id" @click="handleCamera(item, index)">
          <div class="title">门店名称：{{item.name}}</div>
          <div class="title">负责人手机号：{{item.shopUserMobile}}</div>
          <div class="flex" style="margin-top: 15px;" v-if="cameraQueryParam.shopId == item.id">
            <a-button size="small" type="primary" @click.stop="handleOpen(item)">开门</a-button>
            <a-button size="small" type="danger" v-if="item.sfClose == 0" style="margin-left: 10px;" @click.stop="handleSuomen(item, index)">一键锁门</a-button>
            <a-button size="small" type="primary" v-if="item.sfClose == 1" style="margin-left: 10px;" @click.stop="handleSuomen(item, index)">扫码开门</a-button>
          </div>
        </a-card>

      </a-layout-sider>
    </a-layout>
  </div>
</template>
<script>
import YsVideo from '@/components/YsVideo'
import { open, fetchList, cameraFetchList, putSuomenStatus } from './api'
export default {
  components: {
    YsVideo
  },
  data() {
    return {
      queryParam: {
        pageNum: 1,
        pageSize: 2**31-1,
        kfUserId: undefined
      },
      cameraQueryParam: {
        pageNum: 1,
        pageSize: 2**31-1,
        shopId: undefined
      },
      storeList: [],
      cameraList: [],
      zhuCameraIndex: 0, // 主摄像头序号
      isShowCamera: true // 是否显示摄像头
    }
  },
  computed: {
    mqttBackInfo() {
      return this.$store.state.user.mqttBackInfo
    },
    shexiangtouList() {
      return this.$store.state.user.mqttBackInfo.storeMonitorEasyResults
    },
    zhuCamarea() {
      console.log(this.zhuCameraIndex, '101>>>>>>');
      return this.cameraList.length?this.cameraList.filter((item, index) => index === this.zhuCameraIndex)[0]:''
    },
    cameraListUnFirstList() {
      
      
      return this.cameraList.length?this.cameraList.filter((item, index) => index !== this.zhuCameraIndex):[]
    }
  },
  watch: {
    mqttBackInfo: {
      handler(newVal) {
        if(this.cameraQueryParam.shopId != this.mqttBackInfo.shopId) {
          this.cameraList = []
          this.cameraQueryParam.shopId = this.mqttBackInfo.shopId
          this.getCameraList()
        }
        
      },
      deep: true
    }
  },
  created() {
   
  },
  activated() {
     if(this.$store.state.user.roles.includes('kf')) {
      this.queryParam.kfUserId = this.$store.state.user.info.userId
    }
    this.getList()
  },
  deactivated() {
    console.log('keepalive失活');
    this.reset()
  },
  methods: {
    handleSuomen(item, index) {
      putSuomenStatus({id: item.id}).then(res => {
        this.$message.success('操作成功')
        this.$set(this.storeList[index], 'sfClose', item.sfClose == 0?1:0)
        // if(item.sfClose == 0) this.$set(item, 'sfClose', 1)
        // if(item.sfClose == 1) this.$set(item, 'sfClose', 0)
      })
    },
    // 切换主摄像头显示
    handleSelectCamera(row) {
      console.log(row)
      this.isShowCamera = false
      setTimeout(() => {
        this.zhuCameraIndex = this.cameraList.findIndex(item => item.deviceCode === row.deviceCode)
        this.isShowCamera = true
      }, 20);
      
    },
    // 切换门店摄像头
    handleCamera(item) {
      if(item.id === this.cameraQueryParam.shopId) return
      this.zhuCameraIndex = 0
      this.cameraList = []
      this.cameraQueryParam.shopId = item.id
      this.getCameraList()
    },
    // 获取门店摄像头列表
    async getCameraList() {
      const res = await cameraFetchList(this.cameraQueryParam)
      this.cameraList = res.data.records
    },
    // 获取门店列表
    async getList() {
      const res = await fetchList(this.queryParam)
      console.log(res);
      this.storeList = res.data.records
      if(this.mqttBackInfo.shopId) {
        this.cameraQueryParam.shopId = this.mqttBackInfo.shopId
      } else if(this.$route.query.shopId) {
        this.cameraQueryParam.shopId = this.$route.query.shopId
      } else {
        this.cameraQueryParam.shopId = this.storeList[0].id
      }
      this.getCameraList()
    },
    handleOpen(item) {
      this.$confirm({
        title: '提示',
        content: '确定开门操作吗？',
        okText: '确定开门',
        cancelText: '取消',
        onOk: () => {
          console.log('OK');
          open({id: item.id}).then(res => {
            this.$message.success('操作成功')
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    click() {
      console.log('返回');
      
    },
    reset() {
      this.storeList = []
      this.cameraList = []
      this.zhuCameraIndex = 0 // 主摄像头序号
      this.isShowCamera = true // 是否显示摄像头
    },
    handleBack() {
      console.log('点击返回');
      
      this.$router.go(-1)
      this.reset()
    }
  }
}
</script>
<style lang="less" scoped>
.flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.flex-wrap{
  flex-wrap: wrap;
}
.layout{
  margin-top: 10px;
  &-main{
    height: 500px;
    background-color: white;
  }
  &-footer{
    background-color: white;
    height: 150px;
    margin-top: 10px;
    padding: 10px;
    overflow-x: auto;
    &-item{
      width: 400px;
      height: 100%;
      background-color: white;
      margin-right: 10px;
      flex-shrink: 0;
      position: relative;
      a{
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 9999999999;
        color: #4076F6;
        &:hover{
          color: #4076F6;
        }
      }
    }
  }
  &-right{
    height: 660px;
    background-color: white;
    margin-left: 10px;
    padding: 10px;
    overflow-y: auto;
  }
}
.c-card{
  margin-bottom: 10px;
  cursor: pointer;
  &.active{
    border-color: #4076F6;
  }
}

</style>